/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
} from "@mantine/core";




const AgreementsListingPage1 = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Modal
        overflow="inside"
        closeOnClickOutside={false}
        size="calc(30vw)"
        opened={open}
        onClose={() => setOpen(!open)}
        title="Delete Agreement"
      ></Modal> */}
      <Text
        style={{
          fontSize: "30px",
          fontWeight: "600",
          letterSpacing: "1px",
          fontFamily: "lexend",
          whiteSpace: "nowrap",
          paddingBottom: "13px",
          position: "relative",
          color: "black",
          textAlign: "center",
          marginTop: "50px"
          // margin: "0 auto"
        }}
      >
        create new agreement
      </Text>
      <div className="flex justify-center my-auto ">
      <div class="mt-8 p-10 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <p
            className="font-bold text-xl"
            style={{
              cursor: "pointer",
              textAlign: "center",
              border: "2px solid lightblue",
              borderRadius: "8px",
              padding: "8px",
              backgroundColor: "#46BDE1",
              color: "white",
              height: "50px",
              width: "400px",
              margin: "0",
              marginBottom: "15px",
            }}
            onClick={() => navigate("/app/agreements_manager/agreements/new")}
          >
            individual agreement
          </p>
          <p
            className="font-bold text-xl"
            style={{
              cursor: "pointer",
              textAlign: "center",
              border: "2px solid lightblue",
              borderRadius: "8px",
              padding: "8px",
              backgroundColor: "#46BDE1",
              color: "white",
              height: "50px",
              width: "400px",
              marginBottom: "15px",
            }}
            onClick={() =>
              navigate("/app/agreements_manager/agreements/new-bulk")
            }
          >
            bulk agreements
          </p>
          <p
            className="font-bold text-xl"
            style={{
              cursor: "pointer",
              textAlign: "center",
              border: "2px solid lightblue",
              borderRadius: "8px",
              padding: "8px",
              backgroundColor: "#46BDE1",
              color: "white",
              height: "50px",
              width: "400px",
              marginBottom: "15px",
            }}
            onClick={() =>
              navigate(
                "/app/agreements_manager/agreements/new-legacy-agreement"
              )
            }
          >
            legacy agreement
          </p>
          <p
            className="font-bold text-xl"
            style={{
              cursor: "pointer",
              textAlign: "center",
              border: "2px solid lightblue",
              borderRadius: "8px",
              padding: "8px",
              backgroundColor: "#46BDE1",
              color: "white",
              height: "50px",
              width: "400px",
              margin: "0",
            }}
            onClick={() =>
              navigate(
                "/app/agreements_manager/agreements/bulk-legacy-agreement"
              )
            }
          >
            bulk legacy agreements
          </p>
        </div>
      </div>
    </>
  );
};

export default AgreementsListingPage1;
