import React from "react";
import BarChart from "../../components/BarChart";
import { Table } from "@mantine/core";

const eStampsData = [
  {
    Denomination: "100",
    State: "Maharashtra",
    Allocated: 136,
    Consumed: 116,
  },
  {
    Denomination: "100",
    State: "Uttar Pradesh",
    Allocated: 80,
    Consumed: 72,
  },
  {
    Denomination: "100",
    State: "Karnataka",
    Allocated: 72,
    Consumed: 72,
  },
  {
    Denomination: "100",
    State: "Tamil Nadu",
    Allocated: 59,
    Consumed: 59,
  },
  {
    Denomination: "100",
    State: "Delhi",
    Allocated: 50,
    Consumed: 42,
  },
  {
    Denomination: "100",
    State: "Haryana",
    Allocated: 39,
    Consumed: 39,
  },
  {
    Denomination: "100",
    State: "West Bengal",
    Allocated: 29,
    Consumed: 29,
  },
  {
    Denomination: "100",
    State: "Madhya Pradesh",
    Allocated: 17,
    Consumed: 17,
  },
  {
    Denomination: "100",
    State: "Punjab",
    Allocated: 10,
    Consumed: 10,
  },
  {
    Denomination: "100",
    State: "Chandigarh",
    Allocated: 6,
    Consumed: 0,
  },
];

const Estamps = () => {
  let sr = 1;
  return (
    <div>
      <h1
        style={{
          fontSize: "22px",
          fontWeight: "600",
          letterSpacing: "1px",
          fontFamily: "lexend",
          whiteSpace: "nowrap",
          paddingBottom: "13px",
          position: "relative",
          color: "black",
          // margin: "0 auto"
        }}
      >
        e-stamps count
      </h1>

      <div style={{ width: "82%", marginLeft: "9%" }}>
        <BarChart data={eStampsData} />
      </div>
      <div>
        <Table striped={true}
         highlightOnHover withTableBorder>
          <thead>
            <tr>
              <th>sr no.</th>
              <th>state</th>
              <th>denomination</th>
              <th>allocated</th>
              <th>consumed</th>
            </tr>
          </thead>
          {eStampsData.map((row, index) => (
            <tbody>
              <tr key={index}>
                <td>{sr++}</td>
                <td>{row.State}</td>
                <td>{row.Denomination}</td>
                <td>{row.Allocated}</td>
                <td>{row.Consumed}</td>
              </tr>
            </tbody>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default Estamps;
