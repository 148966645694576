import { React, useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Anchor,
  Modal,
  Badge,
  Pagination,
  Select,
  Skeleton,
  Table,
  TextInput,
  Button, Textarea,
} from "@mantine/core";
import { Edit, Search, SortAscending, Trash } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { listServiceProviders, getBussinessUnit, disableBu } from "../../utilities/apis/serviceProvider";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getValueForInput, loadingStates } from "../../utilities/utilities";

import AddOrganisationForm from "./AddOrganisationForm";
import {getOrgDetails, orgDetailsUpdate} from "../../utilities/apis/agreements";

const AddOrganisationPage = () => {
  const [configs, setConfigs] = useState({
    list: null,
    serviceProvidersCount: null,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    formOpen: false,
  });

   const [debouncedSearchInput] = useDebouncedValue(configs.searchString, 500);
let srNo = 1;
  const fetchServiceProviders = async () => {
    if (configs.loading === loadingStates.LOADING) {
      return;
    }
    setConfigs((prevState) => ({
      ...prevState,
      loading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(
      listServiceProviders({
        page: configs.page,
        sortByOptions: {
          [configs.sortBy]: configs.sortDirection,
        },
        filterOptions: debouncedSearchInput?.length
          ? {
              OR: [
                {
                  email: {
                    contains: debouncedSearchInput,
                  },
                },
                {
                  name: {
                    contains: debouncedSearchInput,
                  },
                },
                {
                  designation: {
                    contains: debouncedSearchInput,
                  },
                },
                {
                  phone: {
                    contains: debouncedSearchInput,
                  },
                },
              ],
            }
          : null,
      })
    );
    if (resp?.success && resp.serviceProviders) {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        list: resp.serviceProviders,
        serviceProvidersCount: resp.serviceProvidersCount,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        list: null,
      }));
      // showNotification({
      //   title: 'Service Providers',
      //   message: 'Cannot load service providers list.',
      //   color: 'red',
      // });
    }
  };

  const navigate = useNavigate();
  const [bussinessUnitData, setBussinessUnitData] = useState(null);
  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const {data} = await getBussinessUnit();

        if (data) {
        //  console.log("Inside data: ", data);
          setBussinessUnitData(data.businessunitList);
        } else {
          console.error("Invalid business unit response format:", response);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };

    fetchBusinessUnits();
  }, []);
console.log(bussinessUnitData);
  useEffect(() => {
    fetchServiceProviders();
  }, [
    configs.page,
    configs.sortBy,
    configs.sortDirection,
    debouncedSearchInput,
  ]);

  const sortOptions = [
    {
      label: "created",
      value: "createdAt",
    },
    {
      label: "name",
      value: "name",
    },
    {
      label: "designation",
      value: "designation",
    },
  ];
  
const[buId, setBuId] = useState(0);
const[delBuId, setDelBuId] = useState(null);
const[delModal, setDelMOdal]= useState(0);
const[sendBussinessUnitData, setSendBussinessUnitData]=useState({});
const deleteBu = async ()=>{
  try {
    await disableBu({buId: delBuId});
    // if (reaponse.success) {
      showNotification({
        title: "business unit disable",
        color: "green",
        message: "business unit has been disabled"
      });
      setDelBuId(0);
      setDelMOdal(false);
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 3000);
    // } else {
    //   showNotification({
    //     title: "business unit disable",
    //     color: "red",
    //     message: "business unit has not been disabled"
    //   });
    //   setDelBuId(0);
    //   setDelMOdal(false);
    // }
  } catch (error) {
    showNotification({
      title: "business unit disable",
      color: "red",
      message: "business unit has not been disabled"
    });
    // setDelBuId(0);
    // setDelMOdal(false);
  }
}

  return (
    <div className="flex flex-col">
      {/* <ServiceProviderAnalytics /> */}
      <Modal
        opened={configs.formOpen}
        onClose={() => {
          setConfigs((prevState) => ({
            ...prevState,
            formOpen: false,
          }));
          fetchServiceProviders();
          setBuId(0);
        }}
        size="calc(80vw)"
      >
        <AddOrganisationForm
          buId={buId}
          bussinessUnitData={sendBussinessUnitData}
          formClose={() => {
            setConfigs((prevState) => ({
              ...prevState,
              formOpen: false,
            }));
            fetchServiceProviders();
            setBuId(0);
          }}
        />
      </Modal>
     
      
      <div className="flex w-full justify-between items-center">
        <div className="flex mt-10 flex-col">
          <div className="flex items-center">
            <TextInput
              disabled={configs.loading === loadingStates.LOADING}
              value={configs.searchString}
              onChange={(input) => {
                const val = getValueForInput(input);
                setConfigs((prevState) => ({
                  ...prevState,
                  searchString: val,
                }));
              }}
              placeholder="type to search..."
              icon={<Search size={14} />}
            />
            <Anchor
              className="ml-2"
              onClick={() => {
                setConfigs((prevState) => ({
                  ...prevState,
                  searchString: "",
                }));
              }}
            >
              clear
            </Anchor>
          </div>
        </div>
        <div className="flex mt-10 items-center">
        <Button
          onClick={() => {
            setConfigs((prevState) => ({
              ...prevState,
              formOpen: true,
            }));
          }}
          style={{
            backgroundColor: "#5689C0",
            marginRight: "5px",
          }}
        >
          add business unit/region
        </Button>
        </div>
      </div>
      {configs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {configs.loading !== loadingStates.LOADING && bussinessUnitData && (
        <div className="flex mt-4 flex-col">
          <Table striped>
            <thead>
              <tr>
                <th>sr. no.</th>
                <th>bu name</th>
                <th>organization name</th>
                <th>authorised signatory name</th>
                <th>authorised signatory email</th>
                <th>business POC</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {bussinessUnitData.map((row) => (
                <tr key={row.id}>
                  <td>{`${srNo, srNo++}`}</td>
                  <td>{`${row.name} `}</td>
                  <td>{`${row.organization.name}`}</td>
                  <td>{`${row.extraCol.signName}`}</td>
                  <td>{`${row.extraCol.signEmail}`}</td>
                  <td>{`${row.extraCol.poc}`}</td>
                  
                  <td>
                    <div className="flex flex-row">
                      <ActionIcon
                        onClick={() => {
                          setBuId(parseInt(row.id));
                          setSendBussinessUnitData(row);
                          setConfigs({...configs, formOpen: true})
                        }}
                        color="white"
                      >
                        <Edit size={24} />
                      </ActionIcon>
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row">
                      <ActionIcon
                        onClick={
                          ()=>{
                            setDelBuId(parseInt(row.id));
                            setDelMOdal(true);
                          }
                        }
                        color="white"
                      >
                        <Trash color="#F75435" size={24} />
                      </ActionIcon>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {configs.serviceProvidersCount === 0 && (
            <div className="flex justify-center items-center mt-4">
              <div>no users</div>
            </div>
          )}
          {/* {!!configs.serviceProvidersCount && (
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={configs.page}
                onChange={(page) => {
                  setConfigs({
                    ...configs,
                    page,
                  });
                }}
                total={Math.ceil(configs.serviceProvidersCount / 10)}
              />
            </div>
          )} */}
        </div>
      )}
      <Modal
      opened={delModal}
      onClose={() => {
        setDelBuId(0);
        setDelMOdal(false);
      }}
      // size="calc(80vw)"
       >
        <div className="p-4 md:p-5 text-center">
      <svg
        className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
        are you sure you want to disable this business unit?
      </h3>
      <div className="flex flex-row justify-between">
      <button
        type="button"
        style={{"backgroundColor": "#F75435"}}
        className="text-white hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
        onClick={()=>{ deleteBu()}}
      >
        yes, i'm sure
      </button>
      <button
        type="button"
        className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        onClick={()=>{
          setDelBuId(0);
          setDelMOdal(false);
        }}
      >
        no, cancel
      </button>
      </div>
    </div>
      </Modal>
    </div>
  );
};

export default AddOrganisationPage;
