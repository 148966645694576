import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data }) => {
  // Prepare the data for the chart
  const labels = data.map((item) => `${item.State}-${item.Denomination}`);
  const allocated = data.map((item) => item.Allocated);
  const consumed = data.map((item) => item.Consumed);
  const available = data.map((item) => item.Allocated - item.Consumed);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Allocated",
        data: allocated,
        backgroundColor: "#88CEFB",
        categoryPercentage: 1.0, // Remove space between bar groups
        barPercentage: 1.0,
      },
      {
        label: "Consumed",
        data: consumed,
        backgroundColor: "#F78B98",
        categoryPercentage: 1.0, // Remove space between bar groups
        barPercentage: 1.0,
      },
      {
        label: "Available",
        data: available,
        backgroundColor: "#CEE9AE",
        categoryPercentage: 1.0, // Remove space between bar groups
        barPercentage: 1.0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top", // Position of the legend
      },
      title: {
        display: true,
        text: "E-Stamp Counts by State and Denomination",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "state-denomination",
          font: {
            size: 14,
          },
        },
        grid: {
          display: false, // Remove x-axis grid lines
        },
        ticks: {
          font: {
            size: 12, // Adjust font size for x-axis labels
          },
          autoSkip: false, // Prevent skipping of x-axis labels
          maxRotation: 0, // Prevent label rotation
          minRotation: 0,
        },
        categoryPercentage: 0.8, // Control the gap between bars in groups
        barPercentage: 0.8, // Control the gap between bar groups
      },
      y: {
        grid: {
          display: false, // Remove y-axis grid lines
        },
        beginAtZero: true, // Start y-axis at zero
        ticks: {
          stepSize: 10, // Adjust y-axis interval
          font: {
            size: 12, // Adjust font size for y-axis labels
          },
        },
        title: {
          display: true,
          text: "E-Stamp Counts",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return <Bar  data={chartData} options={options} />;
};

export default BarChart;
