/** @format */

import React, { useState, useEffect } from "react";
import {
  Text,
  Textarea,
  Select,
  TextInput,
  SegmentedControl,
} from "@mantine/core";
import { camelCaseToTitle } from "../../utilities/utilities";

const LegacyAgreementParty = ({
  legacyAgreementFormValue,
  dropDownNames,
  partyName,
  setDropDownNames,
  setLegacyAgreementFormValue,
}) => {
  if (!legacyAgreementFormValue[partyName].type) {
    legacyAgreementFormValue[partyName].type = "Entity";
  }
  const type = legacyAgreementFormValue[partyName].type || "Entity";
  // console.log("type of sanjf: ", type);

  const [phone, setPhone] = useState("");

  //   const handlePhoneChange = (e) => {
  //     const value = e.target.value;

  //     // Allow only up to 10 digits
  //     if (value.length <= 10 && /^\d*$/.test(value)) {
  //       setPhone(value);

  //       // Update the form state only for a complete 10-digit number
  //       if (value.length === 10) {
  //         form.setValue(`${partyName}.phone`, value);
  //       }
  //     }
  //   };

  // Debugging: Log the current phone state and form state value
  //   console.log("Local phone state:", phone);
  //   console.log("Form state for phone:", form.values[`${partyName}.phone`]);
  // const [type, setType] = useState("Entity");

  return (
    <div className="flex flex-col">
      <br></br>
      <div className="flex justify-between" style={{ marginTop: "10px" }}>
        <Text color="#46BDE1">{camelCaseToTitle(partyName).toLowerCase()}</Text>
        <SegmentedControl
          value={legacyAgreementFormValue[`${partyName}`].type}
          color="cyan"
          data={["Entity", "Individual"]}
          onChange={(val) => {
            setLegacyAgreementFormValue({
              ...legacyAgreementFormValue,
              [`${partyName}`]: {
                ...legacyAgreementFormValue[partyName],
                type: val,
              },
            });
          }}
          
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {type === "Entity" && (
          <>
            <Select
              required
              className="inputCustom "
              label="entity type"
              style={{ width: "100%", marginLeft: "0px", lineHeight: "1.55" }}
              data={[
                { value: "Company", label: "Company" },
                { value: "LLP", label: "LLP" },
                { value: "Partnership Firm", label: "Partnership Firm" },
                { value: "Sole Proprietary", label: "Sole Proprietary" },
              ]}
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    entityType: val,
                  },
                });
              }}
              
              value={legacyAgreementFormValue[`${partyName}`].entityType}
            />
            <Select
              required
              searchable
              creatable
              className="inputCustom"
              label="registered name"
              placeholder="enter registered name"
              data={dropDownNames}
              getCreateLabel={(query) => `+ add ${query}`}
              onCreate={(query) => {
                setDropDownNames([...dropDownNames, query]);
              }}
              
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    name: val,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].name || ""}
              style={{
                width: "100%",
                lineHeight: "1.55",
              }}
            />
            {/* <TextInput
              required
              label="Authorised Signatory Name"
              placeholder="Enter Authorised Signatory Name"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    signatory: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].signatory|| ""}
            /> */}
            {/* <TextInput
              required
              label="Authorised Signatory Email ID"
              placeholder="Enter Authorised Signatory Email ID"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    email: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].email|| ""}
            /> */}
            {/* <TextInput
              label="Business POC"
              placeholder="Enter Business POC"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    representative: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].representative|| ""}
              
            /> */}
            {/* <div></div> */}
            <Textarea
              label="registered address"
              placeholder="enter registered address"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    address: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].address|| ""}
            />
            {/* <Textarea
              label="Corporate Address"
              placeholder="Enter Corporate Address"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    corporateAddress: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].corporateAddress|| ""}
            /> */}
          </>
        )}
        {type === "Individual" && (
          <>
            <TextInput
              required
              label="individual name"
              placeholder="enter individual name"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    indi_name: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].indi_name|| ""}
            />
            <TextInput
              label="father's name"
              placeholder="enter father's Name"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    indi_fatherName: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].indi_fatherName|| ""}
            />
            {/* <Textarea
              label="Residence Address"
              placeholder="Enter Residence Address"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    indi_address: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].indi_address|| ""}
            /> */}
            <TextInput
              label="PAN o."
              placeholder="enter PAN no."
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    indi_pan: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].indi_pan|| ""}
            />
            {/* <TextInput
              required
              label="Email ID"
              placeholder="Enter Email ID"
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    indi_email: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].indi_email|| ""}
            /> */}
            {/* <TextInput
              label="Phone No."
              placeholder="Enter 10 digit phone no."
              onChange={(val) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  [`${partyName}`]: {
                    ...legacyAgreementFormValue[partyName],
                    indi_phone: val.target.value,
                  },
                });
              }}
              value={legacyAgreementFormValue[`${partyName}`].indi_phone|| ""}
            /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default LegacyAgreementParty;
