const actions = {
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_WORK_FLOW: "SET_USER_WORK_FLOW",
  SET_WORKSPACE_SETTINGS: "SET_WORKSPACE_SETTINGS",
  SET_WORKSPACE_USERS: "SET_WORKSPACE_USERS",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  SET_ALL_NOTIFICATION: "SET_ALL_NOTIFICATION",
  SET_AGREEMENT_WORKFLOW: "SET_AGREEMENT_WORKFLOW",
};

export default actions;
