/** @format */

import { useEffect } from "react";
import flat from "flat";

const userPartyCreateHook = (partyName, form, parties, initialState) => {
  const agreementFormJson = flat.unflatten(form.values);
  useEffect(() => {
    const [party] = parties?.filter(
      ({ name }) => name === agreementFormJson[partyName].name
    );

    if (party) {
      const tempParty = {
        type: party.type,
        entityType: party.companyType? party.companyType : party.entityType, 
        corporateOfficeAddress: party.corporateOfficeAddress,
        name: party.name,
        email: party.email,
        representative: party.representative,
        signatory: party.signatory,
        address: party.residenceOf,
      };
      form.setValues((prevValues) =>
        flat({
          ...initialState,
          ...flat.unflatten(prevValues),
          [partyName]: tempParty,
        })
      );
    }
  }, [agreementFormJson[partyName].name]);
};

export default userPartyCreateHook;
