/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Anchor, Table, Text, Button, Pagination } from "@mantine/core";
import { parseISO } from "date-fns";
import { showNotification } from "@mantine/notifications";
import colors from "../utilities/design";
import { formatDate, loadingStates } from "../utilities/utilities";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers";
import { MyTasks, TeamTasks, getUserTasks } from "../utilities/apis/tasks";
import TaskModal from "../components/TaskModal";
import StatsCard from "../components/StatsCard";
import HamburgerMenuAgreements from "./HamburgerMenuAgreements";

const TasksPage = (status) => {
  const taskStatus = status.status;
  const [tasksType, setTasksType] = useState("My_Tasks");
  const [page, setPage] = useState(1);
  const [myTasksConfig, setMyTasksConfig] = useState({
    loading: loadingStates.LOADING,
    myTasksData: null,
  });
  const [teamTasksConfig, setTeamTasksConfig] = useState({
    loading: loadingStates.LOADING,
    myTasksData: null,
  });

  let statusTab;
  if(status.status==="PENDING"){
    statusTab = "pending"
  } else if(status.status==="ACTIONED"){
    statusTab = "actioned"
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiWrapWithErrorWithData(MyTasks({page: page, statusTab: statusTab}));
        // console.log("repsonse for tasks:", response);
        if (response) {
          setMyTasksConfig({
            loading: loadingStates.NO_ACTIVE_REQUEST,
            myTasksData: response,
          });
          console.log("myTaskConfigs: ", myTasksConfig);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    const fetchTeamTaskData = async () => {
      try {
        const response = await apiWrapWithErrorWithData(TeamTasks({page: page, statusTab: statusTab}));
        
        if (response) {
          setTeamTasksConfig({
            loading: loadingStates.NO_ACTIVE_REQUEST,
            myTasksData: response,
          });
          console.log("teamTaskConfigs: ", teamTasksConfig);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
    fetchTeamTaskData();
  }, [page]);
  let serialNumber = (page - 1) * 10 + 1;

  return (
    <>
      {/* <div className="flex justify-end">
        <HamburgerMenuAgreements />
      </div> */}

      <div className="flex flex-row">
        {/* <StatsCard
          cardText="All tasks"
          textNumber={100}
          bulletPoints={[
            {
              bulletText: "25 Case Tasks",
              bulletColor: "#d9b342",
            },
            {
              bulletText: "25 Notice Tasks",
              bulletColor: "#39ea11",
            },
            {
              bulletText: "50 Agreement Tasks",
              bulletColor: "#a70e37",
            },
          ]}
        />
        <StatsCard
          cardText="Cases Tasks"
          textNumber={50}
          bulletPoints={[
            {
              bulletText: "10 Pending",
              bulletColor: "#B327F5",
            },
            {
              bulletText: "40 Completed",
              bulletColor: "#ED9013",
            },
          ]}
        />
        <StatsCard
          cardText="Notices Tasks"
          textNumber={50}
          bulletPoints={[
            {
              bulletText: "10 Pending",
              bulletColor: "#B327F5",
            },
            {
              bulletText: "40 Completed",
              bulletColor: "#ED9013",
            },
          ]}
        />
        <StatsCard
          cardText="Agreement Tasks"
          textNumber={50}
          bulletPoints={[
            {
              bulletText: "10 Pending",
              bulletColor: "#B327F5",
            },
            {
              bulletText: "40 Completed",
              bulletColor: "#ED9013",
            },
          ]}
        /> */}
      </div>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1
          style={{
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
            textTransform: "uppercase",
            fontFamily: "Montserrat",
            whiteSpace: "nowrap",
            paddingBottom: "13px",
            position: "relative",
            color: "black",
            margin: "0 auto",
          }}
        >
          Tasks
        </h1>

        <HamburgerMenuAgreements />
      </div>

      <div className="flex justify-around items-center mt-4 hover:text-co">
        <Button
          className={`${
            tasksType === "My_Tasks" ? "bg-blue-500 text-white" : ""
          } hover:bg-blue-400 hover:text-white`}
          variant="outline"
          onClick={() => setTasksType("My_Tasks")}
        >
          My Tasks
        </Button>
        <Button
          className={`${
            tasksType === "Team_Tasks" ? "bg-blue-500 text-white" : ""
          } hover:bg-blue-400 hover:text-white`}
          variant="outline"
          onClick={() => setTasksType("Team_Tasks")}
        >
          My Team's Tasks
        </Button>
      </div>

      {/* FOR ACTIONED, MYTASKS */}

      {taskStatus === "ACTIONED" &&
        tasksType === "My_Tasks" &&
        myTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        myTasksConfig.myTasksData?.tasksCount && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  {/* <th>Status</th> */}
                  {/* <th>Ageing</th>
                  <th>&nbsp;</th> */}
                </tr>
              </thead>
              <tbody>
                {myTasksConfig.myTasksData.tasks
                  .map((row) => (
                    <tr key={row.id}>
                      <td>{serialNumber++}</td>
                      <td>{row.title}</td>
                      <td>{row.status}</td>
                      <td>{row.createdBy.name}</td>
                      <td>{row.createdAt.slice(0, 10)}</td>
                      {/* <td>
                        <Badge
                          color={agreementStatusColors[row.status] || "orange"}
                        >
                          {row.status}
                        </Badge>
                      </td> */}
                      {/* <td>
                        {"--"}
                      </td>

                      <td>
                        --
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(myTasksConfig.myTasksData?.tasksCount / 10)}
              />
            </div>
          </div>
        )}
      {taskStatus === "ACTIONED" &&
        tasksType === "My_Tasks" &&
        myTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        !myTasksConfig.myTasksData?.tasksCount && (
          <div
            className="flex justify-center items-center"
            style={{
              minHeight: "300px",
            }}
          >
            No agreements
          </div>
        )}


      {/* FOR ACTIONED, TeamTASKS */}

      {taskStatus === "ACTIONED" &&
        tasksType === "Team_Tasks" &&
        teamTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        teamTasksConfig.myTasksData?.tasksCount && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  {/* <th>Status</th> */}
                  {/* <th>Ageing</th>
                  <th>&nbsp;</th> */}
                </tr>
              </thead>
              <tbody>
                {teamTasksConfig.myTasksData.tasks
                  .map((row) => (
                    <tr key={row.id}>
                      <td>{serialNumber++}</td>
                      <td>{row.title}</td>
                      <td>{row.status}</td>
                      <td>{row.createdBy.name}</td>
                      <td>{row.createdAt.slice(0, 10)}</td>
                      {/* <td>
                        <Badge
                          color={agreementStatusColors[row.status] || "orange"}
                        >
                          {row.status}
                        </Badge>
                      </td> */}
                      {/* <td>
                        {"--"}
                      </td>

                      <td>
                        --
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(teamTasksConfig.myTasksData?.tasksCount / 10)}
              />
            </div>
          </div>
        )}
      {taskStatus === "ACTIONED" &&
        tasksType === "My_Tasks" &&
        teamTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        !teamTasksConfig.myTasksData?.tasksCount && (
          <div
            className="flex justify-center items-center"
            style={{
              minHeight: "300px",
            }}
          >
            No agreements
          </div>
        )}

      {/* FOR PENDING MYTASKS  */}

      {taskStatus === "PENDING" &&
        tasksType === "My_Tasks" &&
        myTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        myTasksConfig.myTasksData?.tasksCount && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  {/* <th>Status</th> */}
                  {/* <th>Ageing</th>
                  <th>&nbsp;</th> */}
                </tr>
              </thead>
              <tbody>
                {myTasksConfig.myTasksData.tasks.map((row) => (
                    <tr key={row.id}>
                      <td>{serialNumber++}</td>
                      <td>{row.title}</td>
                      <td>{row.status}</td>
                      <td>{row.createdBy.name}</td>
                      <td>{row.createdAt.slice(0, 10)}</td>
                      {/* <td>
                        <Badge
                          color={agreementStatusColors[row.status] || "orange"}
                        >
                          {row.status}
                        </Badge>
                      </td> */}
                      {/* <td>
                        {"--"}
                      </td>

                      <td>
                        --
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(myTasksConfig.myTasksData?.tasksCount / 10)}
              />
            </div>
          </div>
        )}
      {taskStatus === "PENDING" &&
        tasksType === "Team_Tasks" &&
        teamTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        !teamTasksConfig.myTasksData?.tasksCount && (
          <div
            className="flex justify-center items-center"
            style={{
              minHeight: "300px",
            }}
          >
            No agreements
          </div>
        )}


        {/* FOR PENDING TEAMTASKS  */}

      {taskStatus === "PENDING" &&
        tasksType === "Team_Tasks" &&
        teamTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        teamTasksConfig.myTasksData?.tasksCount && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  {/* <th>Status</th> */}
                  {/* <th>Ageing</th>
                  <th>&nbsp;</th> */}
                </tr>
              </thead>
              <tbody>
                {teamTasksConfig.myTasksData.tasks.map((row) => (
                    <tr key={row.id}>
                      <td>{serialNumber++}</td>
                      <td>{row.title}</td>
                      <td>{row.status}</td>
                      <td>{row.createdBy.name}</td>
                      <td>{row.createdAt.slice(0, 10)}</td>
                      {/* <td>
                        <Badge
                          color={agreementStatusColors[row.status] || "orange"}
                        >
                          {row.status}
                        </Badge>
                      </td> */}
                      {/* <td>
                        {"--"}
                      </td>

                      <td>
                        --
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(teamTasksConfig.myTasksData?.tasksCount / 10)}
              />
            </div>
          </div>
        )}
      {taskStatus === "PENDING" &&
        tasksType === "Team_Tasks" &&
        teamTasksConfig.loading === loadingStates.NO_ACTIVE_REQUEST &&
        !teamTasksConfig.myTasksData?.tasksCount && (
          <div
            className="flex justify-center items-center"
            style={{
              minHeight: "300px",
            }}
          >
            No agreements
          </div>
        )}
    </>
  );
};

export default TasksPage;
