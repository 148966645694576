import axios from "axios";
import { headersProvider } from "../apiHelpers";
import constants from "../constants";

export const uploaddocumentAWS = ({ formData }) =>
  axios.post(`${constants.utilityHost}/files/attachdocumenttoAws`, formData, {
    headers: headersProvider(),
  });

export const attachDocuments = ({ fileData }) =>
  axios.post(`${constants.utilityHost}/files/adddocument`, fileData, {
    headers: headersProvider(),
  });

export const getAllDocumentCategories = ({ tab, filter }) =>
  axios.post(
    `${constants.utilityHost}/files/getalldocumentcategory`,
    { tab, filter },
    {
      headers: headersProvider(),
    }
  );

export const openDocument = ({ hostedLink }) =>
  axios.post(
    `${constants.utilityHost}/files/viewdocument`,
    { hostedLink },
    {
      headers: headersProvider(),
    }
  );

export const removeDocument = ({ id }) =>
  axios.post(
    `${constants.utilityHost}/files/deletedocument`,
    { id },
    {
      headers: headersProvider(),
    }
  );

export const updateDocument = ({ fileData, id }) =>
  axios.put(
    `${constants.utilityHost}/files/updatedocument/${id}`,
    { fileData },
    {
      headers: headersProvider(),
    }
  );

export const getTagWords = ({corporate})=>{
  return axios.post(
    `${constants.utilityHost}/files/gettagwords`,
    {
      corporate
    },
    {
      headers: headersProvider(),
    }
  );
}
export const getCategories=()=>
  axios.get(
    `${constants.utilityHost}/files/getcategories`,
    {
      headers: headersProvider(),
    }
  )
  
  export const getUserList=()=>
    axios.get(
      `${constants.utilityHost}/getuserslist`,
      {
        headers: headersProvider(),
      }
    )