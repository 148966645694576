import { Button, Text, TextInput } from "@mantine/core";
import React, { useState } from "react";
import styles from "../components/Header/AppHeader.module.css";
import {
  assetsPrefix,
  getValueForInput,
  loadingStates,
  validateEmail,
} from "../utilities/utilities";
import { At } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers";
import { resetPasswordAPI } from "../utilities/apis/serviceProvider";
import { showNotification } from "@mantine/notifications";

const ForgotPassword = () => {
    const [disable, setDisable] = useState(false);
    const [email, setEmail] = useState("");
    const handleResetPassword = async(e)=>{
        setDisable(true);
        e.preventDefault();
        const response = await apiWrapWithErrorWithData(resetPasswordAPI({email: email}));
        setDisable(false);
        showNotification({
            title: "reset password",
            message: response.message,
            color: response.success? "green": "red",
        });
        // alert(response.message);
    }
  return (
    <div>
      
      <div className="w-screen h-screen bg-white">
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex flex-col w-80 justify-center">
            {/* <Text className="text-2xl text-blue-900 text-center">forgot password?</Text> */}
            <img
              style={{width: "12rem"}}
              src={`${assetsPrefix}/images/trueCounselLogo.png`}
              alt="TrueCounsel"
              className="flex justify-center"              
            />
            <TextInput
              value={email}
              onChange={(e)=> setEmail(e.target.value)}
              className="my-2"
              label="Email"
              placeholder="Enter email"
              icon={<At size={14} />}
            />

            <Button
            onClick={(e)=>{
                handleResetPassword(e);
            }}
            className="my-2"
            disabled={disable}
            >
              reset password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
