/** @format */

import { Button, Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  templatesDetailsApi,
  templatesUpdateEditorContent,
} from "../../utilities/apis/templates";
import colors from "../../utilities/design";
import { loadingStates } from "../../utilities/utilities";
import Editor from "../Editor";

function TemplateEditor() {
  const [templatesConfig, setTemplatesConfig] = useState({
    loading: loadingStates.LOADING,
    editorContent: { ops: [{ insert: "content goes here..." }] },
    templateTitle: "",
  });

  const { templateId } = useParams();
  //   const navigate = useNavigate();
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
  });

  const getTemplate = async (tempId) => {
    // setTemplatesConfig((prevState) => ({
    //   ...prevState,
    //   loading: loadingStates.LOADING,
    // }));
    const response = await apiWrapWithErrorWithData(
      templatesDetailsApi({ templateId: tempId })
    );
    if (response?.success && response?.template) {
      console.log(response);
      setTemplatesConfig({
        loading: loadingStates.NO_ACTIVE_REQUEST,
        editorContent: response.template.quillDelta,
        templateTitle: response.template.name,
      });
    } else {
      // showNotification({
      //   color: "red",
      //   title: "Templates Details",
      //   message: "Failed to load template details.",
      // });
      setTemplatesConfig({
        ...templatesConfig,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    }
  };

  const saveTemplate = async (tempId) => {
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const response = await apiWrapWithErrorWithData(
      templatesUpdateEditorContent({
        templateId: tempId,
        quillDelta: templatesConfig.editorContent,
        templateTitle: templatesConfig.templateTitle,
      })
    );
    if (response?.success) {

      setTemplatesConfig({
        ...templatesConfig,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        editorContent: response.quillContent.quillDelta,
      });
      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } else {
      showNotification({
        color: "red",
        title: "Templates Details",
        message: "Failed to load template details.",
      });
    }
  };

  useEffect(() => {
    getTemplate(templateId);
  }, []);

  console.log(templatesConfig);

  return (
    <div className="flex flex-col">
      {templatesConfig.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          <Skeleton height={40} className="mb-11" />
          <Skeleton height={40} className="mb-11" />
          <Skeleton height={40} className="mb-11" />
          <Skeleton height={40} className="mb-11" />
          <Skeleton height={40} className="mb-11" />
        </div>
      )}
      {uiConfigs.loading === loadingStates.LOADING ||
        templatesConfig.loading === loadingStates.LOADING ? (
        <BeatLoader color={colors.primary} size={10} />
      ) : (
        <>
          <p><strong>Disclaimer: </strong> Please click the "Save" button to save the template contents.</p>
          <div className="flex mb-3">
            <Button onClick={() => saveTemplate(templateId)}>Save</Button>
          </div>
        </>
      )}
      {templatesConfig.loading !== loadingStates.LOADING && (
        <div className="flex flex-col">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
              Template Title
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={templatesConfig.templateTitle} onChange={(e) => setTemplatesConfig({...templatesConfig, templateTitle: e.target.value})} />
          </div>
          <div>
            <Editor
              content={templatesConfig.editorContent}
              onContentChange={(content) => {
                setTemplatesConfig((prevState) => ({
                  ...prevState,
                  editorContent: content,
                }));
              }}
              templateId={templateId}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TemplateEditor;
