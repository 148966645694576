import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Skeleton } from '@mantine/core';
import { apiWrapWithErrorWithData } from '../utilities/apiHelpers';
import { loadingStates } from '../utilities/utilities';
import { getDashboardTAT } from '../utilities/apis/users';

function TatChart({ month }) {
  const [configs, setConfigs] = useState({
    listLoading: loadingStates.NO_ACTIVE_REQUEST,
    expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
    agreementAndCase: null,
    inAndOutNotices: null
  });
  const fetchDashboardTAT = async () => {
    setConfigs((prevState) => ({
      ...prevState,
      expenseLoading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(getDashboardTAT({ month }));
    if (resp?.success) {
      setConfigs((prevState) => ({
        ...prevState,
        expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
        agreementAndCase: resp.data.agreementAndCase,
        inAndOutNotices: resp.data.inAndOutNotices
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        expenseLoading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      // showNotification({
      //   title: 'TAT chart',
      //   message: "Couldn't load TAT charts",
      //   color: 'red',
      // });
    }
  };

  useEffect(() => {
    fetchDashboardTAT();
  }, [month]);

  return (
    <div className="mb-5">      
      {configs.expenseLoading === loadingStates.LOADING
        && <Skeleton style={{ height: '200px' }} className="mt-4 mx-8" visible />}
      {configs.expenseLoading === loadingStates.NO_ACTIVE_REQUEST && configs?.agreementAndCase
        ? (
          <div>
            <div
            className="mt-4 border-solid border-gray-100 border-2 rounded-lg p-4 mt-4"
              style={{ border: 'none', boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1)'}}
            >
              <p className="mt-2">TAT chart for Agreements and Cases</p>
                <Bar
                data={{
                    labels: configs.agreementAndCase.map((el) => `${el.month} ${el.year}`),
                    datasets: [
                    {
                        label: 'Agreements',
                        backgroundColor: '#7F669D',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.agreementAndCase.map(ele => ele.avgTotal['agreement']),
                    },
                    {
                        label: 'Cases',
                        backgroundColor: '#FFB9B9',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.agreementAndCase.map(ele => ele.avgTotal['case']),
                    },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      y: {
                        title: {
                          display: true,
                          text: 'Time (days)',
                        },
                      },
                      x: {
                        title: {
                          display: true,
                          text: 'Months',
                        },
                      },
                    },
                }}
                />
            </div>
            <div
              className="mt-4 border-solid border-gray-100 border-2 rounded-lg p-4 mt-4"
                style={{ border: 'none', boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.1)'}}
              >
              <p className="mt-10">TAT chart for Incoming and Outgoing Notices</p>
              <Bar
                data={{
                    labels: configs.inAndOutNotices.map((el) => `${el.month} ${el.year}`),
                    datasets: [
                    {
                        label: 'Incoming Notices',
                        backgroundColor: '#9D5353',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.inAndOutNotices.map(ele => ele.avgTotal['incoming']),
                    },
                    {
                        label: 'Outgoing Notices',
                        backgroundColor: '#89B5AF',
                        barThickness: 30,
                        barPercentage: 0.5,
                        data: configs.inAndOutNotices.map(ele => ele.avgTotal['outgoing']),
                    },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      y: {
                        title: {
                          display: true,
                          text: 'Time (days)',
                        },
                      },
                      x: {
                        title: {
                          display: true,
                          text: 'Months',
                        },
                      },
                    },
                }}
              />
            </div>
          </div>
        ): null }
    </div>
  );
}

export default TatChart;
