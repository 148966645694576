import React from "react";
import { useNavigate } from "react-router-dom";
import { Paperclip, Bell, Template } from "tabler-icons-react";
import { Notification } from "@mantine/core";
import Logo from "./trueCounselLogo.png";
import Section2 from "../components/AfterLogin/Section2/Section2";
import Section3 from "../components/AfterLogin/Section3/Section3";
import AppHeader from "../components/Header/AppHeader";

const LandingPageAfterLogin = () => {
  const navigate = useNavigate();

  const handleAgreementsManagerClick = () => {
    navigate("/app/agreements_manager");
  };

  const handleNotificationsClick = () => {
    navigate("/app/agreements_manager/notifications");
  };

  const handleDisputesManagerClick = () => {
    Notification.info({
      title: "Disputes Manager",
      message: "Coming Soon!",
      position: "bottom-right",
    });
  };

  return (
    <div>
      <div className="w-full relative flex flex-col">
      <AppHeader hide={true}/>
        {/* <LandingPageHeader /> */}
        <div
          style={{
            height: "48px",
            width: "100%",
            height: "25vh",
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="/">
            <img width={380} height={51} src={Logo} objectFit="cover" />
          </a>
        </div>
        <Section2 />
        {/* <Section3 /> */}
      </div>
    </div>
  );
};

export default LandingPageAfterLogin;
