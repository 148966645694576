import {
  ActionIcon,
  Anchor,
  Button,
  MultiSelect,
  Progress,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { Trash } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  attachDocuments,
  getCategories,
  getTagWords,
  getUserList,
  openDocument,
  updateDocument,
  uploaddocumentAWS,
} from "../../utilities/apis/documents";
import { showNotification } from "@mantine/notifications";

const AddDocuments = ({
  selectedFile,
  modal,
  setModal,
  corporate,
  handleReload,
  data,
}) => {
  const [fileData, setFileData] = useState(
    data
      ? data
      : {
          title: "",
          tageWords: "",
          category: "",
          SubCategory: "",
          subsubCategory: "",
          validate: "",
          corporate: corporate,
          visibility: true,
          accessUser: "",
          name: "",
          url: "",
          key: "",
        }
  );
  const [file, setFile] = useState(
    selectedFile > 0
      ? {
          url: fileData.url,
          name: fileData.name,
        }
      : null
  );
  const [tagWords, setTagWords] = useState(["tag1"]);
  const [users, setUsers]= useState(null);
  const [categoryData, setCategoryData] = useState({
    categories: ["nda"],
    subCategories: ["nda"],
    subSubCategories: ["nda"],
  });
  function validate() {
    if (
      fileData?.title?.length === 0 ||
      file === null ||
      fileData.category?.length === 0 ||
      fileData.SubCategory?.length === 0
    ) {
      showNotification({
        color: "red",
        message: "please fill all the required fields.",
        title: "fill required fields",
      });
      return false;
    } else {
      return true;
    }
  }
  useEffect(() => {
    const getAllTagWords = async () => {
      try {
        const res = await apiWrapWithErrorWithData(getTagWords({ corporate }));
        setTagWords(res["uniqueTags"]);
      } catch (error) {
        console.error("Error fetching tag words:", error);
      }
    };
    const getCategory = async () => {
      const response = await apiWrapWithErrorWithData(getCategories());
      if (response.success) {
        setCategoryData({
          categories: response.uniqueCategories,
          subCategories: response.uniqueSubCategories,
          subSubCategories: response.uniqueSubSubCategories.filter(
            (sub) => sub !== ""
          ),
        });
      }
    };
    const getUsers = async()=>{
      const respo = await apiWrapWithErrorWithData(getUserList());
      if (respo.success) {
        setUsers(respo.userData);
      }
    }
    getUsers();
    getCategory();
    getAllTagWords();
  }, [corporate]);

  const addDocument = async () => {
    if (validate()) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await apiWrapWithErrorWithData(
          uploaddocumentAWS({ formData })
        );
        console.log("Response from AWS: ", response);
        const updatedFileData = {
          ...fileData,
          name: response.data?.fileName,
          url: response?.data?.hostedLink,
          key: response?.data?.key,
        };
        if (response.success) {
          const responsefile = await apiWrapWithErrorWithData(
            attachDocuments({ fileData: updatedFileData })
          );
          console.log("Response from attachDocuments: ", responsefile);
          if (response.success) {
            setModal(false);

            showNotification({
              title: "Add Document",
              message: "Document added successfully",
              color: "green",
            });
            handleReload();
          } else {
            showNotification({
              title: "Add Document",
              message: "Document not added",
              color: "red",
            });
          }
        }
      } catch (error) {
        console.error("Error adding document: ", error);
        showNotification({
          title: "Add Document",
          message: "Document not added",
          color: "red",
        });
      }
    }
  };

  const updateDocumenthandle = async () => {
    if (validate()) {
      if (!file.url) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await apiWrapWithErrorWithData(
          uploaddocumentAWS({ formData })
        );

        if (response.success) {
          const updatedFileData = {
            ...fileData,
            name: response.data?.fileName,
            url: response?.data?.hostedLink,
            key: response?.data?.key,
          };
          const response2 = await apiWrapWithErrorWithData(
            updateDocument({ id: selectedFile, fileData: updatedFileData })
          );
          if (response2.success) {
            setModal(false);

            showNotification({
              title: "update document",
              message: "document updated successfully",
              color: "green",
            });
            window.location.reload();
          } else {
            showNotification({
              title: "update document",
              message: "Document not updateed",
              color: "red",
            });
          }
        } else {
          showNotification({
            title: "update document",
            message: "Document not updateed",
            color: "red",
          });
        }
      } else if (file.url) {
        const response3 = await apiWrapWithErrorWithData(
          updateDocument({ id: selectedFile, fileData: fileData })
        );
        if (response3.success) {
          setModal(false);
          showNotification({
            title: "Add Document",
            message: "Document added successfully",
            color: "green",
          });
          window.location.reload();
        } else {
          showNotification({
            title: "Add Document",
            message: "Document not added",
            color: "red",
          });
        }
      }
    }
  };

  const viewDocuemnt = async () => {
    if (file.url) {
      const response = await apiWrapWithErrorWithData(
        openDocument({ hostedLink: file.url })
      );
      if (response.success) {
        window.open(`${response.signedUrl}`, "_blank");
      }
    } else {
      showNotification({
        title: "view document",
        message: "you uploaded this file from local",
        color: "yellow",
      });
    }
  };

  return (
    <div className="m-4">
      <div className="grid grid-cols-3 gap-4">
        <TextInput
          style={{ width: "82%" }}
          required
          className="w-full m-auto"
          label="title of document"
          placeholder="enter document name..."
          value={fileData.title}
          onChange={(e) => setFileData({ ...fileData, title: e.target.value })}
        />
        <MultiSelect
          style={{ width: "82%" }}
          searchable
          creatable
          className="w-full m-auto"
          label="tag words"
          placeholder="enter tag words..."
          data={tagWords}
          value={fileData.tageWords.split(",") || "b"} // Split the comma-separated string back into an array for the MultiSelect
          onChange={(val) => {
            const newTagWords = val.join(","); // Convert the array to a comma-separated string
            setFileData({ ...fileData, tageWords: newTagWords });
          }}
          getCreateLabel={(query) => `+ Add ${query}`}
          onCreate={(query) => {
            setTagWords([...tagWords, query]);
          }}
        />
        <DatePicker
          style={{ width: "82%" }}
          className="m-auto"
          label="validity"
          placeholder="select validity date"
          value={fileData.validate ? new Date(fileData.validate) : null}
          onChange={(val) => setFileData({ ...fileData, validate: val })}
        />
        <Select
          style={{ width: "82%" }}
          className="m-auto"
          searchable
          required
          creatable
          data={categoryData.categories}
          label="select category"
          placeholder="select category..."
          value={fileData.category}
          onChange={(val) => setFileData({ ...fileData, category: val })}
          getCreateLabel={(query) => `+ Add ${query}`}
          onCreate={(query) => {
            setCategoryData({
              ...categoryData,
              categories: [...categoryData.categories, query],
            });
          }}
        />
        <Select
          style={{ width: "82%" }}
          className="m-auto"
          searchable
          required
          creatable
          data={categoryData.subCategories}
          label="select subcategory"
          placeholder="select subcategory..."
          value={fileData.SubCategory}
          onChange={(val) => setFileData({ ...fileData, SubCategory: val })}
          getCreateLabel={(query) => `+ Add ${query}`}
          onCreate={(query) => {
            setCategoryData({
              ...categoryData,
              subCategories: [...categoryData.subCategories, query],
            });
          }}
        />
        <Select
          style={{ width: "82%" }}
          className="m-auto"
          searchable
          creatable
          data={categoryData.subSubCategories}
          label="select sub-subcategory"
          placeholder="select sub-subcategory..."
          value={fileData.subsubCategory}
          onChange={(val) => setFileData({ ...fileData, subsubCategory: val })}
          getCreateLabel={(query) => `+ Add ${query}`}
          onCreate={(query) => {
            setCategoryData({
              ...categoryData,
              subSubCategories: [...categoryData.subSubCategories, query],
            });
          }}
        />
        <Select
          style={{ width: "82%" }}
          className="m-auto"
          data={[
            { label: "visibile to all", value: true },
            { label: "restricted", value: false },
          ]}
          label="select visibility"
          placeholder="select visibility"
          value={fileData.visibility}
          onChange={(val) => setFileData({ ...fileData, visibility: val })}
        />
        {!fileData.visibility &&
        <MultiSelect 
        required
        style={{ width: "82%" }} 
        className="m-auto"
        label="access to..."
        placeholder="select users"
        value={fileData.accessUser.split(",")} 
          onChange={(value) => {
            const newAccessUser = value.join(","); 
            setFileData({ ...fileData, accessUser: newAccessUser });
          }}
        data={users? users:[{label: "1", value: 1}]}
        />}
      </div>
      <div>
        <div class="container mt-4">
          {file === null ? (
            <div className="flex items-center justify-center w-full m-4">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    ariaHidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span>click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    all file types are supported
                  </p>
                </div>
                <input
                  onChange={(e) => setFile(e.target.files[0])}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full mx-6">
              <div className="flex flex-row w-full mt-4">
                <img
                  //   className="m-auto"
                  src="/assets/images/downloadFileLogo.svg"
                  alt="file upload"
                />

                <div className="flex flex-col pl-3 w-10/12 ">
                  <div className="flex flex-row justify-between">
                    <div className="text-xl">{file.name}</div>
                  </div>
                  <div className="mt-3">
                    <Progress color={"teal"} size="sm" value={100} />
                  </div>
                </div>

                <div className="flex items-center">
                  <Anchor
                    // onClick={downloadFile(index)}
                    className="mx-3 "
                    color="#46BDE1"
                    // disabled={file.downloading === loadingStates.LOADING}
                  >
                    <Text>{file.fileName}</Text>
                  </Anchor>
                  <Button
                    className="mx-3 w-60"
                    color="#46BDE1"
                    variant="outline"
                    onClick={viewDocuemnt}
                  >
                    Open
                  </Button>
                  <ActionIcon color="red">
                    <Trash
                      onClick={() => setFile(null)}
                      // onClick={deleteFile(index)}
                    />
                  </ActionIcon>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center m-4">
        <Button onClick={() => setModal(false)} color="red" className="mr-6">
          cancel
        </Button>
        <Button
          onClick={selectedFile > 0 ? updateDocumenthandle : addDocument}
          className="ml-6"
          color="green"
        >
          {selectedFile > 0 ? "update document" : "add document"}
        </Button>
      </div>
    </div>
  );
};

export default AddDocuments;
