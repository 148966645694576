import { Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getAgreementByStatus } from "../../utilities/apis/agreements";
import { loadingStates } from "../../utilities/utilities";

function AgreementGraph2( {configs}) {
  const groupedData = {
    '0-3': 0,
    '4-7': 0,
    '8-10': 0,
    '10+': 0,
  };
  configs?.data?.agreementsAgeing?.forEach(item => {
    const ageing = parseInt(item.ageing, 10);
  if (ageing <= 3) {
    groupedData['0-3'] += item.count;
  } else if (ageing > 3 && ageing <= 7) {
    groupedData['4-7'] += item.count;
  } else if (ageing > 7 && ageing <= 10) {
    groupedData['8-10'] += item.count;
  } else if (ageing > 10) {
    groupedData['10+'] += item.count;
  }
  });
  const labels = Object.keys(groupedData);
  const dataValues = Object.values(groupedData);
  const backgroundColors = labels.map((label, index) => {
    // Customize colors based on the label or index
    switch (label) {
      case '0-3':
        return 'rgb(70, 189, 225)'; // Color for Label1
      case '4-7':
        return 'rgb(70, 189, 225)'; // Color for Label2
      case '8-10':
        return '#FDAE38'; // Color for Label3
      default:
        return '#F75435'; // Default color
    }
  });
  // const [days,setdays] = useState(["5","10","15", "15+"]);
  // console.log("bhai graph k liye: ", configs);
  return (
    <div style={{ width: "47%", margin: "auto" }}>
      <div
        className="border-solid border-gray-100 border-2 rounded-lg mt-4"
        style={{
          border: "none",
          boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1)",
          width: "100%",
          padding: "2.5%",
        }}
      >
        <div className="ml-4">{"ageing (days)"}</div>
        {configs.loading === loadingStates.LOADING && (
          <Skeleton style={{ height: "200px" }} className="mt-4 mx-8" visible />
        )}
        {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
          configs.data.agreementsAgeing && (
            <div
              className="w-full"
              style={{
                height: "200px",
                width: "100%",
              }}
            >
              <Bar
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Ageing",
                      backgroundColor: backgroundColors,
                      barThickness: 30,
                      barPercentage: 0.5,
                      borderRadius: 10,
                      data: dataValues
                    },
                  ],
                }}
              
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'agreements',
                      },
                      grid: {
                        display: false,  
                      },
                      ticks: {
                        display: false, 
                      }
                    },
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'days',
                      },
                      grid: {
                        display: false,  
                      },
                    },
                  },
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default AgreementGraph2;