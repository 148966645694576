import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import img1 from "./ImagesDashboard/signed.png";
import total_img from "./ImagesDashboard/total_contract.png";
import approval from "./ImagesDashboard/right.png";
import review from "./ImagesDashboard/review.png";
import signed from "./ImagesDashboard/signed.png";
import cross from "./ImagesDashboard/approval_status.png";
import renewals from "./ImagesDashboard/renewals.png";
import tasks from "./ImagesDashboard/tasks.png";
import e_stamps from "./ImagesDashboard/e_stamps.png";
import signed_status from "./ImagesDashboard/signing_status.png"
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/selectors";

const COLORS = {
  primaryDark: "rgb(248,244,252)",
  primaryLight: "#f8f4fc",
};

const MenuLabel = styled.label`
  background-color: ${COLORS.primaryLight};
  position: fixed;
  top: 6rem;
  right: 2rem;
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
  text-align: center;
`;

const NavBackground = styled.div`
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background-image: radial-gradient(
    ${COLORS.primaryDark},
    ${COLORS.primaryLight}
  );
  height: 4rem;
  width: 0.25vw;
  // border: 2px solid red;
  border-radius: 50%;
  // border: 2px solid red;
  z-index: 600;
  transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
  transition: transform 0.8s;
`;

const Icon = styled.span`
  position: relative;
  background-color: ${(props) => (props.clicked ? "transparent" : "black")};
  width: 3rem;
  height: 2px;
  // border: 2px solid red;
  display: inline-block;
  margin-top: 3.5rem;
  transition: all 0.3s;

  &::before,
  &::after {
    content: "";
    background-color: black;
    width: 3rem;
    height: 2px;
    display: inline-block;

    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  &::before {
    top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "0" : "0.8rem")};

    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }

  ${MenuLabel}:hover &::before {
    top: ${(props) => (props.clicked ? "0" : "-1rem")};
  }
  ${MenuLabel}:hover &::after {
    top: ${(props) => (props.clicked ? "0" : "1rem")};
  }
`;

const Navigation = styled.nav`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 600;
  width: ${(props) => (props.clicked ? "20%" : "0")};
  opacity: ${(props) => (props.clicked ? "1" : "0")};

  transition: width 0.8s, opacity 0.8s;
`;
const GlassyButton = styled.div`
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.25);
  }
`;

const List = styled.ul`
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  // border: 2px solid red;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 70%;
`;
const ItemLink = styled(NavLink)`
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 300;
  text-decoration: none;
  color: black;
  padding: 1rem 2rem;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: #288ce4;
    transform: translateX(1rem);
  }
`;

function HamburgerMenuAgreements() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const currentUser = useSelector(selectUserData) || {};
  const loginAs = currentUser.loginAs;
  const url = window.location.href;
  const parts = url.split("/");
  const ending = parts[parts.length - 1];
  const itemTitle = ending
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  // console.log(itemTitle);
  const [BoxData, setBoxData] = useState(
    [
      {
        title: "total agreements",
        icon: null,
        value: "760",
        color: "red-400",
        imgSrc: total_img,
      },
      loginAs.id === 1 ||
      loginAs.id === 2 ||
      loginAs.id === 4 ||
      loginAs.id === 5
        ? {
            title: "review status",
            icon: null,
            value: "294",
            color: "blue-900",
            imgSrc: review,
          }
        : null,
      loginAs.id === 1 ||
      loginAs.id === 2 ||
      loginAs.id === 4 ||
      loginAs.id === 6
        ? {
            title: "approval status",
            icon: null,
            value: "265",
            color: "purple-900",
            imgSrc: cross,
          }
        : null,
      loginAs.id === 1 ||
      loginAs.id === 2 ||
      loginAs.id === 4 ||
      loginAs.id === 7
        ? {
            title: "signature status",
            icon: null,
            value: "256",
            color: "green-900",
            imgSrc: signed_status,
          }
        : null,

      loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4
        ? {
            title: "signed",
            icon: null,
            value: "456",
            color: "pink-900",
            imgSrc: signed,
          }
        : null,
        loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4
        ?{
        title: "renewals",
        icon: null,
        value: "760",
        color: "red-400",
        imgSrc: renewals,
      }: null,
      // {
      //   title: "tasks",
      //   icon: null,
      //   value: "294",
      //   color: "blue-900",
      //   imgSrc: tasks,
      // },
      // loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4
      //   ?{
      //   title: "e-stamps",
      //   icon: null,
      //   value: "256",
      //   color: "green-900",
      //   imgSrc: e_stamps,
      // }: null,
    ].filter(item => item && item.title !== itemTitle)
  );
  
  const navigate = useNavigate();
  const handleBoxClick = (title) => {
    const currentPath = "/app/agreements_manager";
    if (title === "e-stamps") {
      window.location.href = "https://www.emsigner.com/Areas/login?ReturnUrl=%2feMsecure%2feStamping%2feStampingSummary";
    } else if (title === "tasks") {
      navigate(`${currentPath}/tasks/pending`);
    } else {
    const newPath = `${currentPath}/${title.toLowerCase().replace(/\s/g, "-")}`;
    navigate(newPath);
    }
  };

  return (
    <>
      <div
        className="flex"
        
      >
        {" "}
        {BoxData.map((item, index) => (
          <GlassyButton
            key={index}
            onClick={() => handleBoxClick(item.title)}
            to={`/app/agreements_manager/${item.title
              .toLowerCase()
              .replace(/\s/g, "-")}`}
          >
            <img
              src={item.imgSrc}
              alt="Button Image"
              className="w-9 h-9 m-2"
              title={item.title}
            />
          </GlassyButton>
        ))}
      </div>
    </>
  );
}

export default HamburgerMenuAgreements;
