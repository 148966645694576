import TreeNode from "./NodeTree";
import React from "react";
import "./aa.css";
const DocumentsTree = ({ documents, handleReload }) => {
  return (
    <div className="documents-tree-container">
      {Object.keys(documents).map((category) => (
        <TreeNode
          handleReload={handleReload}
          key={category}
          node={{ name: category, ...documents[category] }}
        />
      ))}
    </div>
  );
};

export default DocumentsTree;
