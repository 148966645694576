import colors, { themeColor } from "./design";

// export const caseStatusValues = ['CREATED', 'MEDIATION', 'EVIDENCE',
// 'ARGUMENT', 'COMPLETED', 'ARCHIVED'];
export const caseStatusValues = [
  "FILING",
  "NOTICE",
  "PLEADINGS",
  "EVIDENCE",
  "ARGUMENTS",
  "COMPLETED",
];

// export const taskStatusValues = ['CREATED', 'IN PROCESS', 'COMPLETED', 'ARCHIVED'];
export const taskStatusValues = [
  "CREATED",
  "IN PROCESS",
  "COMPLETED",
  "EXPIRED",
];

export const noticeStatusValues = [
  "REQUEST RECEIVED",
  "DRAFTING",
  "SENT",
  "NEGOTIATION",
  "COMPLETED",
];

export const caseStatusColors = {
  // CREATED: themeColor(colors.created),
  // MEDIATION: 'pink',
  // EVIDENCE: 'violet',
  // ARGUMENT: 'indigo',
  // COMPLETED: 'green',
  // ARCHIVED: 'gray',
  // CREATED: themeColor(colors.created),
  FILING: "pink",
  NOTICE: "red",
  EVIDENCE: "violet",
  ARGUMENT: "indigo",
  COMPLETED: "green",
  PLEADINGS: "gray",
};

export const noticeStatusColors = {
  // CREATED: themeColor(colors.created),
  // DRAFT: 'pink',
  // SENT: 'violet',
  // NEGOTIATION: 'indigo',
  // CONCLUDED: 'green',
  "REQUEST RECEIVED": "gray",
  DRAFTING: "pink",
  SENT: "violet",
  NEGOTIATION: "indigo",
  COMPLETED: "green",
};

export const noticeTypeColors = {
  // INCOMING: 'green',
  // OUTGOING: 'pink',
  Incoming: "green",
  Outgoing: "pink",
};

export const noticeRequestStatusColors = {
  CREATED: themeColor(colors.created),
  APPROVED: "green",
  REJECTED: "red",
};

export const agreementStatusColors = {
  CREATED: "orange",
  // DRAFT: 'pink',
  // SIGNATURE: 'violet',
  // SIGNED: 'green',
  SENT: "violet",
  DECLINED: "pink",
  PARTIALLYCOMPLETED: "gray",
  COMPLETED: "green",
  CANCELLED: "red",
};

export const getStatusText = (status) => {
  switch (status) {
    case "CREATED":
      return "intiated";
      break;
    case "Review":
      return "under review";
      break;
    case "Approval":
      return "pending approval";
      break;
    case "Signature":
      return "finalised";
      break;
    case "SENT":
      return "pending signature";
      break;
    case "CANCELLED":
      return "cancelled";
      break;
    case "SIGNED":
      return "completed";
    case "PARTIALLYCOMPLETED":
      return "Partially completed";
    // Add more cases as needed
    default:
      return "under review";
  }
};
export const getStatusColor = (status) => {
  switch (status) {
    case "CREATED":
      return "yellow";
      break;
    case "Review":
      return "rgba(255, 131, 69, 1)";
      break;
    case "Approval":
      return "pink";
      break;
    case "Signature":
      return "grape";
      break;
    case "SENT":
      return "violet";
      break;
    case "CANCELLED":
      return "red";
      break;
    case "SIGNED":
      return "green";
    case "PARTIALLYCOMPLETED":
      return "indigo";
    // Add more cases as needed
    default:
      return "gray";
  }
};

export const taskStatusColors = {
  CREATED: themeColor(colors.created),
  "IN PROCESS": themeColor(colors["in process"]),
  COMPLETED: themeColor(colors.completed),
  // ARCHIVED: 'gray',
  EXPIRED: "red",
};

export const moduleColors = {
  case: themeColor(colors.case),
  agreement: themeColor(colors.agreement),
  notice: themeColor(colors.notice),
  task: themeColor(colors.task),
  cases: themeColor(colors.cases),
  agreements: themeColor(colors.agreements),
  notices: themeColor(colors.notices),
  tasks: themeColor(colors.tasks),
};
